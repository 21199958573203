jQuery(document).ready(function($) {
	/* Mobile Menu */
	mobileNavigationMenuSelector = ".mobile-navigation-menu"
	menu = new Mmenu( ".mobile-navigation-menu", {
		extensions: [
			"position-back",
			"position-top",
		],
		slidingSubmenus: true
	}, {
		classNames: {
			selected: "active"
		},
		offCanvas: {
			page: {
				selector: "#page"
			}
		}
	});

	api = menu.API;
	$(".mobile-menu-button").click(function() {
		if ($(mobileNavigationMenuSelector).hasClass("mm-menu_opened")) {
			api.close();
		}
		else {
			api.open();
		}
	});

	/* Accessible menu */
	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".hasChildren > .text-block-3").click(function(event) {
			if (!$(this).parents(".hasChildren").hasClass("open")) {
				$(".button-link").removeClass("open");
				event.preventDefault();
				$(this).parent(".hasChildren").addClass("open");
			}
		});
	}
	else {
		$(".button-link").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}
	$(".accesible-navigation-menu").accessibleMenu();

	/* Search */
	$(".search-cb-wrapper").on("click", function() {
		$(".search-content").addClass('active');
		$(".exit-cb-wrapper").show();
		$(".search-cb-wrapper").hide();
	});

	$(".exit-cb-wrapper").on("click", function() {
		$(".search-content").removeClass('active');
		$(".exit-cb-wrapper").hide();
		$(".search-cb-wrapper").show();
	});

	// hide "more" links if no website is provided
	$('.more-text-arrow-link[href=" "]').css('display', 'none');
});
